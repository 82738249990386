import { render, staticRenderFns } from "./LiefengTable.vue?vue&type=template&id=42574323&scoped=true&"
import script from "./LiefengTable.vue?vue&type=script&lang=js&"
export * from "./LiefengTable.vue?vue&type=script&lang=js&"
import style0 from "./LiefengTable.vue?vue&type=style&index=0&id=42574323&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42574323",
  null
  
)

export default component.exports